import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "mobx-react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import App from "./App";
import "./index.scss";
import "./polyfills/ie-11";
import BarpHomeStore from "./store/barp-home-store";
import BarpMotorStore from "./store/barp-motor-store";
import BusinessStore from "./store/business-store";
import CallRequestStore from "./store/call-request-store";
import ContentStore from "./store/content-store";
import GroupHealthStore from "./store/group-health-store";
import GroupLifeStore from "./store/group-life-store";
import HomeStore from "./store/home-store";
import LifeStore from "./store/life-store";
import MarketingMessageStore from "./store/marketing-message-store";
import MotorStore from "./store/motor-store";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
});
// GA.init();

ReactDOM.render(
  <Provider
    contentStore={new ContentStore()}
    motorStore={new MotorStore()}
    barpMotorStore={new BarpMotorStore()}
    barpHomeStore={new BarpHomeStore()}
    lifeStore={new LifeStore()}
    homeStore={new HomeStore()}
    businessStore={new BusinessStore()}
    groupLifeStore={new GroupLifeStore()}
    groupHealthStore={new GroupHealthStore()}
    marketingMessageStore={new MarketingMessageStore()}
    callRequestStore={new CallRequestStore()}
  >
    <IntlProvider locale="en">
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);
