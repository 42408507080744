import { makeAutoObservable } from "mobx";
// TODO add the API_Host as a optional constructor parameter
class BarpLookupStore {
  constructor(rootStore, endpoint, defaultValues = {}) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.endpoint = endpoint;
    this.options = defaultValues;
    this.fetched = false;
    this.serverError = false;
  }

  async get() {
    /**
     * Fetch field options from api for quick quote form. Response data will
     * override any default values automatically.
     */
    const axios = await import("axios");

    if (this.fetched) {
      return this.options;
    }

    try {
      const response = await axios.get(
        process.env.REACT_APP_LMS_HOST_URL + this.endpoint,
        {
          headers: {
            Authorization: process.env.REACT_APP_LMS_API_KEY,
          },
        }
      );
      this.options = { ...this.options, ...response.data };

      return this.options;
    } catch (error) {
      this.serverError = true;
    }
  }
}
export default BarpLookupStore;
